import { User } from "../models/User";
import MonoApi from "./MonoApi";

export class AuthApi {
  static async authTelegram(payload: {
    inviteCode?: string | null;
    tgWebAppData: {
      hash: string;
      auth_date: string;
      chat_instance: string;
      chat_type: string;
      user: string;
    }
  }) {
    const res = await MonoApi.post(`auth/oauth/telegram`, payload);

    return res.data as {
      accessToken: string;
      refreshToken: string;
      user: User;
    };
  }
}
import { useTranslation } from "react-i18next";
import { MonoButton } from "../../../components";
import { formatAmount } from "../../../utils";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../services/RootStore";
import { useDispatch } from "react-redux";
import { close } from "../../../contexts/modal-slice";
import { Button } from "../../../components/common/button";

const PackagePopup: React.FC = observer(() => {
  const { modalStore, marketStore, playerStore } = useStore();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!modalStore.marketItem || !playerStore.user) return <div></div>;

  return <>
    <section className="flex flex-col items-center gap-1 pb-6">
      <img className="w-20 mt-6 rounded-xl" src={modalStore.marketItem.imageUrl} alt="token" />
      <p className="text-center text-3xl px-3 mb-3 font-semibold">
        { modalStore.marketItem.name }
      </p>

      {/* <p className="text-sm px-16 mb-3 text-center">
        {t('digitalAssetsExperience')}
      </p> */}

      <p className="text-xs px-3 text-center">
        {t('profitPerHour')}
      </p>
      
      <div className="flex gap-3 mt-1">
        {
          modalStore.marketItem.pointPerHour ?
          <div className="flex gap-1">
            <img className="object-contain w-4" src="/images/gold-coin.png" alt="coin" />
            <p className="text-base font-semibold">+ {formatAmount(modalStore.marketItem.pointPerHour || 0)}</p>
          </div> : null
        }
        {
          modalStore.marketItem.energy ?
          <div className="flex gap-1">
            <img className="object-contain w-3" src="/images/lighting.png" alt="coin" />
            <p className="text-base font-semibold">+ {formatAmount(modalStore.marketItem.energy || 0)}</p>
          </div> : null
        }
      </div>

      <Button 
        className={`w-full mt-6`}
        onClick={() => {
          if (playerStore.user!.point >= modalStore.marketItem!.price) {
            if(modalStore.marketItem) {
              marketStore.buyItem(modalStore.marketItem.id);
            };
            dispatch(close("packageModal"));
          }
        }}
        isLoading={marketStore.isBuying}
        disabled={playerStore.user.point < modalStore.marketItem.price}
      >
        <p className="text-2 font-semibold">
          {
            modalStore.marketItem.level == 0 ? t('unlock') : t('upgrade')
          }
        </p>
        <img className="object-contain w-7" src="/images/gold-coin.png" alt="coin" />
        <p className="text-2xl font-semibold">{formatAmount(modalStore.marketItem.price || 0)}</p>
      </Button>
    </section>
  </>
})

export default PackagePopup;
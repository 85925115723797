import { GameItem } from "../models/GameItem";
import { Quest } from "../models/Quest";
import MonoApi from "./MonoApi";

export class MarketApi {
  static async getAllItem(): Promise<GameItem[]> {
    const res = await MonoApi.get(`marketplace/listing/game-item`);

    return res.data.data
  }

  static async buyItem(gameItemId: string): Promise<GameItem[]> {
    const res = await MonoApi.post(`game-item/buy-item/${gameItemId}`);

    return res.data.data
  }
}
import { action, makeObservable, observable } from "mobx";
import { Quest } from "../models/Quest";
import { GameItem } from "../models/GameItem";

export class ModalService {
  @observable quest?: Quest;
  @observable marketItem?: GameItem;

  constructor() {
    makeObservable(this);
  }

  @action
  openQuestModal(quest: Quest) {
    this.quest = quest;
  }

  @action
  openMarketModal(marketItem: GameItem) {
    this.marketItem = marketItem;
  }
}
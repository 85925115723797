import { useTranslation } from "react-i18next";
import { MonoButton } from "../../../components";
import { formatAmount } from "../../../utils";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../services/RootStore";
import { useDispatch } from "react-redux";
import { close } from "../../../contexts/modal-slice";
import { useState } from "react";
import { Button } from "../../../components/common/button";

const TeleTaskModal: React.FC = observer(() => {
  const { modalStore, questStore } = useStore();
  const dispatch = useDispatch();
  const [isCheck, setIsCheck] = useState(false);
  
  const { t } = useTranslation();

  const claimReward = () => {
    if (modalStore.quest) {
      questStore.claimReward(modalStore.quest.id);
    }
    
    dispatch(close("teleTaskModal"));
  }

  const doQuest = () => {
    if (modalStore.quest) {
      if (!isCheck) {
        window.open(modalStore.quest.link, "_blank");

        setIsCheck(true);
        return;
      }

      setIsCheck(false);

      questStore.completeQuest(modalStore.quest!.id);
    }
  }

  return <>
    <section className="flex flex-col items-center gap-1">
      <img className="w-15 mb-6 mt-6" src={modalStore.quest?.imageUrl} alt="token" />
      <p className="text-center text-3xl px-3 mb-3 font-semibold">
        {modalStore.quest?.name}
      </p>

      <div className="flex gap-3 mb-9">
        <img className="object-contain w-7" src={"/images/gold-coin.png"} alt="coin" />
        <p className="text-2xl font-semibold">{formatAmount(modalStore.quest?.point || 0)}</p>
      </div>

      <Button
        disabled={!(modalStore.quest && !modalStore.quest.isClaimed)}
        onClick={() => 
          (modalStore.quest && questStore.isQuestCompleted(modalStore.quest.id)) ? claimReward() : doQuest()
        }
        isLoading={questStore.isClaimming}
        className="w-full"
      >
        {
          (modalStore.quest && questStore.isQuestCompleted(modalStore.quest.id)) ? t('claim') : isCheck ? t('check') : t('go')
        }
      </Button>
    </section>
  </>
})

export default TeleTaskModal;
import { RollDice } from "../models/Dice";
import { User } from "../models/User";
import MonoApi from "./MonoApi";

export class UserApi {
  static async getUser(id?: string): Promise<User> {
    const res = await MonoApi.get(`user/${id || ''}`);

    return res.data;
  }

  static async rollDice(): Promise<RollDice> {
    const res = await MonoApi.get(`player/roll-dice`);

    return res.data;
  }
}
import { RollDice } from "../models/Dice";
import { Quest } from "../models/Quest";
import { User } from "../models/User";
import MonoApi from "./MonoApi";

export class QuestApi {
  static async getAllQuest(): Promise<Quest[]> {
    const res = await MonoApi.get(`quest`);

    return res.data.data
  }

  static async claimReward(questId: string) {
    const res = await MonoApi.post(`quest/claim/${questId}`);
  }
}
import { action, makeObservable, observable } from "mobx";
import { GameItem } from "../models/GameItem";
import { MarketApi } from "../api/MarketApi";
import { toast } from "sonner";
import { PlayerService } from "./PlayerService";
import { rootStore } from "./RootStore";

export class MarketService {
  @observable data?: GameItem[];
  @observable isBuying = false;

  constructor() {
    makeObservable(this);
  }

  @action
  async getAllListing() {
    const items = await MarketApi.getAllItem();
    this.data = items;
  }

  @action
  async buyItem(gameItemId: string) {
    this.isBuying = true;
    toast.promise(async () => {
      await MarketApi.buyItem(gameItemId);
      await this.getAllListing();
      await rootStore.playerStore.getPlayer();
    }, {
      loading: 'Buying...',
      success: () => 'Buy item sucessfully!',
      error: () => 'Buy item failed!',
      finally: () => {
        this.isBuying = false
      }
    })
  }
}
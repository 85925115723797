import { useDispatch } from "react-redux";
import { show } from "../../../contexts/modal-slice";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../services/RootStore";
import { formatAmount } from "../../../utils";
import { FaUnlockKeyhole } from "react-icons/fa6";

interface CardProps {
  img: string;
  title: string;
  point: number;
  energy: number;
  price: number;
  level: number;
  onClick?: () => void;
}

const Card = ({ img, title, point: point, energy, price: price, level, onClick }: CardProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div
      onClick={() => {
        dispatch(show('packageModal'));
        onClick?.();
      }}
      className="flex flex-col bg-[#282A2F] rounded-xl items-center p-2 w-full relative overflow-hidden">
      {
        level == 0 &&
        <div className="w-full h-full absolute bg-[#00000096] top-0 flex justify-center items-center">
          <FaUnlockKeyhole color="white" size={24}/>
        </div>
      }
      <div className="flex items-start border-b-[#686A6D] border-b-2 pb-1 w-full min-h-[81px]">
        {
          level == 0 &&
          <div className="flex items-center rounded-2xl overflow-hidden w-1/3 min-w-16">
            <img src={img} alt="" />
          </div>
        }

        <div className="flex flex-col gap-1 ml-2">
          <div>
            <p className="text-sm">{title}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-xs text-[#686A6D]">{t('profitPerHour')}</p>
            <div className="flex items-center gap-1">
              <p className="text-sm">{`+${formatAmount(point)}`}</p>
              <img className="w-3" src="/images/gold-coin.png" alt="" />
            </div>
            {
              energy > 0 &&
              <div className="flex items-center gap-1">
                <p className="text-sm">{`+${formatAmount(energy)}`}</p>
                <img className="w-2" src="/images/lighting.png" alt="" />
              </div>
            }
          </div>
        </div>
      </div>

      <div className="flex items-center w-full pt-2">
        <p className="text-center text-base w-1/3 min-w-16">Lv {level}</p>
        <div className="flex flex-[2] gap-1 border-l-[#686A6D] border-l-2 pl-2">
          <p className="text-base">{formatAmount(price)}</p>
          <img className="w-4 h-auto object-contain" src="/images/gold-coin.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Card;

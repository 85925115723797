import { Icon } from "./type";

const IcMine: React.FC<Icon> = ({ color = '#848484' }) => {
  return <>
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.6781 8.69775L21.3123 11.3207C21.2504 11.3883 21.1716 11.484 21.0816 11.5684L3.35706 29.2986C3.1313 29.5712 2.83507 29.7768 2.50064 29.8929C2.16621 30.009 1.80635 30.0312 1.46018 29.9571C1.15352 29.8891 0.869022 29.7448 0.633101 29.5375C0.39718 29.3301 0.217521 29.0664 0.110812 28.771C0.00410225 28.4756 -0.026186 28.158 0.0227613 27.8478C0.0717086 27.5375 0.198292 27.2446 0.390765 26.9964C0.488463 26.8754 0.593741 26.7607 0.705967 26.6531L18.4699 8.89481L18.6781 8.69775Z" fill={color}/>
      <path d="M18.9089 6.94165C19.0288 6.65465 19.1819 6.38267 19.3649 6.13116C20.333 5.12926 21.318 4.14988 22.3143 3.17613C22.4124 3.06752 22.5323 2.98072 22.6661 2.92129C22.7999 2.86187 22.9446 2.83115 23.091 2.83115C23.2374 2.83115 23.3822 2.86187 23.516 2.92129C23.6498 2.98072 23.7696 3.06752 23.8677 3.17613C24.8621 4.15176 25.8471 5.13677 26.8228 6.13116C26.9354 6.23046 27.0256 6.35257 27.0873 6.48941C27.1491 6.62624 27.1811 6.77468 27.1811 6.92481C27.1811 7.07494 27.1491 7.22332 27.0873 7.36015C27.0256 7.49699 26.9354 7.6191 26.8228 7.7184C25.8715 8.69215 24.9091 9.66028 23.9297 10.6059C23.3274 11.1687 22.7646 11.1688 22.1736 10.544C21.2674 9.63777 20.3499 8.74283 19.4606 7.81411C19.248 7.54248 19.0632 7.25018 18.9089 6.94165Z" fill={color}/>
      <path d="M24.4531 11.7203L27.1042 8.99611C28.2398 10.783 29.0656 12.7488 29.547 14.8105C30.2481 17.7921 30.2191 20.8989 29.4626 23.8669C29.3781 24.1934 29.2543 24.4917 28.8603 24.4692C28.4663 24.4467 28.3594 24.1709 28.3481 23.8107C28.2053 19.5339 26.8763 15.381 24.5094 11.8161C24.4756 11.7541 24.4475 11.7091 24.4531 11.7203Z" fill={color}/>
      <path d="M20.9634 2.89473L18.3855 5.46135C16.8432 4.70711 15.3066 3.86845 13.6968 3.2099C11.3362 2.26981 8.82934 1.74978 6.28956 1.6733C6.08902 1.67632 5.89245 1.61739 5.72669 1.50448C5.66539 1.44019 5.62017 1.3623 5.59474 1.27719C5.5693 1.19208 5.56437 1.10217 5.58035 1.01479C5.6321 0.915646 5.70305 0.827743 5.78905 0.756241C5.87504 0.684738 5.97438 0.631067 6.0813 0.598279C7.31607 0.333077 8.56619 0.145092 9.82435 0.0354173C13.711 -0.204577 17.5751 0.779659 20.8733 2.84973C20.905 2.86114 20.9352 2.87623 20.9634 2.89473Z" fill={color}/>
      <path d="M24.9653 2.55702C25.3051 2.34836 25.7051 2.25989 26.1011 2.30582C26.4972 2.35175 26.8663 2.5294 27.1492 2.81028C27.4508 3.09343 27.646 3.47147 27.7023 3.88124C27.7587 4.29102 27.6727 4.70771 27.4588 5.06173L24.9653 2.55702Z" fill={color}/>
    </svg>
  </>
}

export default IcMine;
import { createContext, useContext } from "react";
import { PlayerService } from "./PlayerService";
import { QuestService } from "./QuestService";
import { ModalService } from "./ModalService";
import { MarketService } from "./MarketService";

export const rootStore = {
  playerStore: new PlayerService(),
  questStore: new QuestService(),
  modalStore: new ModalService(),
  marketStore: new MarketService()
}

export type TRootStore = typeof rootStore;
const RootStoreContext = createContext<null | TRootStore>(null);

// Tạo ra provider để cung cấp store cho toàn bộ app
// dung trong file index.tsx
export const Provider = RootStoreContext.Provider;

/** tra lai store, chi dung o function component */
export function useStore() {
  /** store này sẽ chứa toàn bộ data */
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }
  return store;
}
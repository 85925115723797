import { Quest } from '../../../models/Quest';
import { Task } from './';
import { TaskProps } from './task';

interface TaskListProps {
  title: string;
  items: ItemProps[];
}

interface ItemProps extends Quest {
  onClick: () => void
}

const TaskList = ({ title, items }: TaskListProps) => {
  return (
    <>
      <div className="mt-4">
        <p className="text-lg">{title}</p>
      </div>

      <div className="flex flex-col align-center gap-2">
        {items.map((quest) => (
          <Task key={quest.id} amount={quest.point} title={quest.name} checked={!quest.isClaimed} img={quest.imageUrl} onClick={quest.onClick} disabled={quest.isClaimed}/>
        ))}
      </div>
    </>
  );
};

export default TaskList;

import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { IcFriends, IcMine, IcEarn } from '../../../icons';
import './menu-bar.scss';
import { RootState } from '../../../contexts/store';
import { useSelector } from 'react-redux';
import { useStore } from '../../../services/RootStore';
import { observer } from 'mobx-react-lite';
import { PlayerService } from '../../../services/PlayerService';
import { GooSpinner } from 'react-spinners-kit';
import MoveTransition from '../../animations/move-transition';

// Hàm tính thời gian đến giờ chẵn kế tiếp (Xh00 phút)
function getTimeUntilNextHour() {
  const now = new Date();
  const nextHour = new Date();
  nextHour.setHours(now.getHours() + 1, 0, 0, 0); // Thiết lập thời gian là giờ chẵn kế tiếp
  return Math.floor((nextHour.getTime() - now.getTime()) / 1000); // Chuyển đổi thời gian còn lại sang giây
}

// Hàm định dạng thời gian từ giây thành mm:ss
function formatTime(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
}

const Menubar = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(getTimeUntilNextHour());

  const isActive = (path: string) => location.pathname === path;

  const { playerStore } = useStore();

  useEffect(() => {
    if (timeLeft <= 0 && (playerStore.user?.energy || 0) >= 1) {
      playerStore.getPlayer();
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft(prevTimeLeft => {
        if (prevTimeLeft <= 1) {
          clearInterval(intervalId);
          return 0;
        }
        return prevTimeLeft - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft, playerStore.user?.energy]);

  const handleRoll = () => {
    const diceEl = document.querySelector<HTMLButtonElement>('#dice')
    if(diceEl) diceEl.click();
  }
  

  return (
    <div className={`menu-bar ${isActive('/') && 'hidden'}`}>
      <div className="menu-bar-bg">
        <img src="/images/bar-bg.png" alt="" />
        <img className='menu-bar-decor' src="/images/bar-center.png" alt="" />
      </div>
      
      <div className="menu-bar-content">
        <NavLink to="/friends" className={`menu-bar-item ${isActive('/friends') ? 'active' : ''}`}>
          <IcFriends />
          <div className="menu-bar-name text-sm">
            Friends
          </div>
        </NavLink>
        <NavLink to="/mine" className={`menu-bar-item ${isActive('/mine') ? 'active' : ''}`}>
          <IcMine />
          <div className="menu-bar-name text-sm">
            Mine
          </div>
        </NavLink>
        <div className={`menu-bar-item main ${!isActive('/game') ? 'p-0' : 'pt-4'}`} onClick={() => navigate('/game')}>
          {
            isActive('/game')
            ?
            <>
              <div onClick={handleRoll} className="menu-bar-name primary-gold mb-5 flex items-center justify-center">
                {/* {playerStore.user?.energy}/{playerStore.user?.dailyEnergy} */}
                {
                  !playerStore.isRolling ?
                  <MoveTransition y={24} style={{
                    width: '42%'
                  }}>
                    <div className='relative flex items-center justify-center text-xl'>
                      <img src="/images/dice-1.png" alt="" style={{
                        opacity: (playerStore.user?.energy || 0) >= 1 ? 1 : .15
                      }}/>

                      {
                        !((playerStore.user?.energy || 0) >= 1) &&
                        <span className="absolute">
                          {formatTime(timeLeft)}
                        </span>
                      }
                    </div>
                  </MoveTransition> :
                  <GooSpinner
                    color={"#f3b000"}
                  />
                }
              </div>
              
            </>
            :
            <div className="menu-bar-name text-5 m-0 p-0">
              Go
            </div>
          }
          
        </div>
        <NavLink to="/earn" className={`menu-bar-item ${isActive('/earn') ? 'active' : ''}`}>
          <IcEarn />
          <div className="menu-bar-name text-sm">
            Earn
          </div>
        </NavLink>
        <NavLink to="/airdrop" className={`menu-bar-item ${isActive('/airdrop') ? 'active' : ''}`}>
          <img src="/images/token.png" alt="" />
          <div className="menu-bar-name text-sm">
            Airdrop
          </div>
        </NavLink>
      </div>
    </div>
  )
})

export default Menubar;

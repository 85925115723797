import { formatAmount } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../services/RootStore";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";


const ProfileBar = observer(() => {
  const navigate = useNavigate();
  const { playerStore } = useStore();

  return (
    <div className="flex items-center justify-between z-[60]">
      <div className="flex items-center">
        <div className="bg-black font-semibold p-1 rounded-full">
          <img src={playerStore.user?.avatarImageUrl || "/images/non-user.png"} alt="" className="rounded-full" style={{
            width: 48
          }}/>
        </div>

        <p className="text-base text-black ml-2">{playerStore?.user?.name}</p>
      </div>

      <div className="flex items-center">
        <div className="bg-[#282A2F] rounded-full p-2 py-1.5 pl-3 mr-2 gap-2 flex items-center cursor-pointer">
          <p className="text-sm">{formatAmount(playerStore?.user?.energy || 0)}</p>
          <img className="w-3" src="/images/lighting.png" alt="" />
        </div>
        <div className="bg-[#282A2F] rounded-full p-2 py-1.5 pl-3 mr-2 gap-2 flex items-center cursor-pointer">
          <p className="text-sm">{formatAmount(playerStore?.user?.point || 0)}</p>
          <img className="w-5" src="/images/gold-coin.png" alt="" />
        </div>

        <div onClick={() => navigate('/settings')} className="bg-[#282A2F] rounded-full flex justify-center items-center py-1.5 px-3 h-full cursor-pointer" style={{ height: '32.8px' }}>
          <img className="w-6" src="/images/ic-setting.png" alt="" />
        </div>
      </div>
    </div>
  );
});

export default ProfileBar;

import { useTranslation } from "react-i18next";
import DailyTask from "./daily-task";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../services/RootStore";
import { useDispatch } from "react-redux";
import { close } from "../../../contexts/modal-slice";

const DailyTaskModal: React.FC = observer(() => {
  const { modalStore, questStore } = useStore();
  const { t } = useTranslation();
  const dispatch = useDispatch();


  const claimReward = () => {
    if (modalStore.quest) {
      questStore.claimReward(modalStore.quest.id);
    }
    
    dispatch(close("dailyTaskModal"));
  }

  return <div className="flex-1 overflow-y-scroll">
    <div className="flex flex-col items-center gap-1 pb-14 overflow-y-scroll">
      <img className="w-15 mb-6 mt-1" src={modalStore.quest?.imageUrl} alt="token" />
      <p className="text-center text-3xl px-10 mb-2 font-semibold">
        {modalStore.quest?.name}
      </p>

      <p className="text-sm px-5 text-center">
        {modalStore.quest?.description}
      </p>

      <div className="grid grid-cols-4 gap-3 w-full mt-3">
        {
          Array.from({
              length: 10
            }, 
            (_, index) => index < (modalStore.quest?.claimedCount || 0) % 10
          )
            .map((i, index) => {
              const quest = modalStore.quest;
              const pointReward = quest?.point || 0;
              const formulaNextReceive = quest?.formulaNextReceive || 0;

              return <DailyTask 
                key={`daily-task-item-${index}`} 
                day={t('day') + ' ' + (index + 1)} 
                award={((pointReward) + (pointReward * formulaNextReceive * index)).toString()} 
                isClaimed={i} 
              /> 
            })
        }
      </div>

      <div className={`absolute bottom-2 bg-neutral-600/75 w-11/12 ${modalStore.quest && !modalStore.quest.isClaimed ? 'primaryBtn' : ''}`} 
        onClick={() => 
          modalStore.quest && !modalStore.quest.isClaimed && claimReward()
        }
      >
        {
          modalStore.quest?.isClaimed ? t('comeBackTomorrow') : t('claim')
        }
      </div>
    </div>
  </div>
})

export default DailyTaskModal;
import { action, makeObservable, observable } from "mobx";
import { User } from "../models/User";
import { UserApi } from "../api/UserApi";
import { AuthApi } from "../api/AuthApi";
import queryString from "query-string";
import Cookies from "js-cookie";
import MonoApi from "../api/MonoApi";

export class PlayerService {
  @observable user?: User;
  @observable isRolling = false;

  constructor() {
    makeObservable(this);
    
    setInterval(() => {
      this.mintPointPerSecond();
    }, 1000);
  }

  @action
  async auth() {
    let telegramPayload: any = queryString.parse(window.location.hash);
    let tgWebAppData: any = queryString.parse(telegramPayload.tgWebAppData);
    let urlParams = new URLSearchParams(window.location.search);

    console.log(telegramPayload);
    console.log(tgWebAppData);

    const authRes = await AuthApi.authTelegram({
      tgWebAppData,
      inviteCode: urlParams.get("tgWebAppStartParam")?.split('invite_')?.[1]
    });

    Cookies.set("accessToken", authRes.accessToken, { secure: true });

    //Update axios instance
    MonoApi
      .interceptors
      .request
      .use(config => {
        config.headers['Authorization'] = `Bearer ${authRes.accessToken}`;

        return config;
      });

    this.user = authRes.user;
  }

  @action
  async getPlayer() {
    console.log("GET");
    const user = await UserApi.getUser();
    this.user = user;
  }

  xpToLevel(xp: number) {
    return Math.floor(Math.pow((4 * Math.pow(xp / 100, 2)) / 81, 1 / 5)) || 1;
  }

  levelToXp(level: number) {
    return parseFloat(
      (Math.pow(level, 2) * 9 * (Math.sqrt(level) / 2)).toFixed(2),
    ) * 100;
  }

  getLevel() {
    return this.xpToLevel(this.user?.xp || 0);
  }

  getNextLevel() {
    const nextLevel = this.getLevel() + 1;
    const nextXp = this.levelToXp(nextLevel);

    return {
      nextLevel,
      nextXp
    }
  }

  //*Player
  @action
  async rollDice() {
    this.isRolling = true;
    const dice = await UserApi.rollDice();

    this.user!.energy = dice.user.energy;

    setTimeout(() => {
      this.user = {
        ...dice.user,
      }
      this.isRolling = false;
    }, 3000);

    return dice;
  }

  @action
  mintPointPerSecond() {
    if (this.user) {
      this.user.point += this.user.pointPerHour / 3600;
    }
  }
}


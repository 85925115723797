import { Icon } from "./type";

const IcFriends: React.FC<Icon> = ({ color = '#848484', isActive }) => {
  return <>
    <svg width="35" height="28" viewBox="0 0 35 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.5789 6.07895C23.5789 9.43626 20.8573 12.1579 17.5 12.1579C14.1427 12.1579 11.421 9.43626 11.421 6.07895C11.421 2.72164 14.1427 0 17.5 0C20.8573 0 23.5789 2.72164 23.5789 6.07895Z" fill={color}/>
      <path d="M28 21.921C28 25.2784 23.299 28 17.5 28C11.701 28 7 25.2784 7 21.921C7 18.5637 11.701 15.8421 17.5 15.8421C23.299 15.8421 28 18.5637 28 21.921Z" fill={color}/>
      <path d="M9.03376 1.8421C9.34675 1.8421 9.65231 1.87237 9.94737 1.93001C9.22855 3.18856 8.81851 4.64136 8.81851 6.18856C8.81851 7.6981 9.20883 9.11776 9.89541 10.3548C9.61649 10.4061 9.32842 10.4329 9.03376 10.4329C6.53916 10.4329 4.51688 8.50979 4.51688 6.13751C4.51688 3.76522 6.53916 1.8421 9.03376 1.8421Z" fill={color}/>
      <path d="M6.08007 26.1579C5.07844 24.9776 4.40925 23.5292 4.40925 21.8358C4.40925 20.1942 5.03814 18.7829 5.98914 17.6228C2.62985 17.8796 0 19.6908 0 21.8869C0 24.1037 2.67607 25.9282 6.08007 26.1579Z" fill={color}/>
      <path d="M26.1816 6.18856C26.1816 7.6981 25.7913 9.11777 25.1047 10.3548C25.3836 10.4061 25.6717 10.4329 25.9663 10.4329C28.4609 10.4329 30.4832 8.50979 30.4832 6.13751C30.4832 3.76522 28.4609 1.8421 25.9663 1.8421C25.6533 1.8421 25.3478 1.87237 25.0527 1.93001C25.7715 3.18856 26.1816 4.64136 26.1816 6.18856Z" fill={color}/>
      <path d="M28.92 26.1579C32.324 25.9282 35.0001 24.1037 35.0001 21.8869C35.0001 19.6908 32.3703 17.8796 29.011 17.6228C29.962 18.7829 30.5908 20.1942 30.5908 21.8358C30.5908 23.5292 29.9217 24.9776 28.92 26.1579Z" fill={color}/>
    </svg>
  </>
}

export default IcFriends;
import { observer } from 'mobx-react-lite';
import { MoveTransition } from '../../../components';
import { Card } from './';
import { useStore } from '../../../services/RootStore';
import { GooSpinner } from 'react-spinners-kit';

const MyCards = observer(() => {
  const { marketStore, modalStore } = useStore();

  if (!marketStore.data) {
    return (
      <div className='w-full h-full d-flex flex justify-center items-center'>
        <GooSpinner
          color={"#f3b000"}
        />
      </div>
    )
  }

  return (
    <MoveTransition y="20vh">
      <div className="grid grid-cols-2 gap-2 w-full">
        {marketStore.data.map((i) => (
          <Card
            key={i.id}
            img={i.imageUrl || ''}
            title={i.name}
            point={i.pointPerHour}
            energy={i.energy}
            price={i.price}
            level={i.level}
            onClick={() => modalStore.openMarketModal(i)}
          />
        ))}
      </div>
    </MoveTransition>
  );
});

export default MyCards;

import { action, makeObservable, observable } from "mobx";
import { Quest } from "../models/Quest";
import { QuestApi } from "../api/QuestApi";
import { toast } from "sonner";
import { t } from "i18next";

export class QuestService {
  @observable data?: Quest[];
  @observable questCompleted: any = {};
  @observable isClaimming = false;

  constructor() {
    makeObservable(this);
  }

  @action
  async getAllQuest() {
    const quests = await QuestApi.getAllQuest();

    this.data = quests;

    return quests;
  }

  async claimReward(questId: string) {
    toast.promise(
      async () => {
        this.isClaimming = true;
        await QuestApi.claimReward(questId);
        this.getAllQuest();
        this.isClaimming = false;
      },
      {
        loading: t('claimming'),
        success: () => t('claimSuccess'),
        error: () => t('claimFailed')
      }
    )
    
    this.getAllQuest();
  }

  @action
  getQuestCompleted() {
    this.questCompleted = JSON.parse(localStorage.getItem("COMPLETED_QUESTS") || "{}");
  }

  saveQuestCompleted() {
    localStorage.setItem("COMPLETED_QUESTS", JSON.stringify(this.questCompleted));
  }

  @action
  completeQuest(questId: string) {
    this.getQuestCompleted();
    this.questCompleted[questId] = true;
    this.saveQuestCompleted();
  }

  @action
  isQuestCompleted(questId: string) {
    this.getQuestCompleted();
    return this.questCompleted[questId] || false;
  }
}
import React from "react";
import "./styles.scss";
import { GooSpinner } from "react-spinners-kit";

export interface ButtonProps {
  children?: React.ReactNode,
  style?: React.CSSProperties,
  className?: string,
  onClick?: () => void,
  isLoading?: boolean,
  disabled?: boolean,
}

export const Button = (props: ButtonProps) => {
  return (
    <button className={`${props.disabled ? '!bg-none !bg-neutral-600/75' : ''} primaryBtn ${props.className}`} onClick={props.onClick}>
      {
        props.isLoading ?
        <GooSpinner color={"white"}/> : 
        props.children
      }
    </button>
  )
}